import { internshipList } from '@/lib/Competences';
import { CompetenceModel } from '@/interfaces/models/competence.interface';

export default class EntityModel {
  model: CompetenceModel;

  constructor() {
    this.model = {
      id: '',
      name: '',
      internship: internshipList[0],
      client: { id: '', value: '' },
      serviceType: { id: '', value: '' },
      count: '',
      securityIsNeeded: false,
      trainingId: '',
      trainingTeam: '',
      isRequalification: false,
      description: '',
      firstShiftDescription: '',
      isActive: false,
      trainingWebLink: '',
      isAcademyOceanRequired: false,
      internshipIsNeeded: 0,
      internshipType: '',
      positionName: '',
      rocketWorkDescription: '',
    };
  }
}
