
import { Component, Vue, Watch } from '@/lib/decorator';
import TabsNav from '@/components/tabs/TabsNavIcons.vue';
import TableTotal from '@/components/table-items/TableTotal.vue';
import AppHeaderBtnAdd from '@/layouts/partials/AppHeaderBtnAdd.vue';
import ArrowReturn from '@/components/icons/ArrowReturn.vue';
import Loading from '@/components/Loading.vue';

import AddBlock from './Add.vue';
import EditBlock from './Edit.vue';

import CompetenceModule from '@/store/settings/competence';
import SettingsModule from '@/store/settings';
import UserModule from '@/store/user';
import AppTable from '@/components/ui/table/Table.vue';
import IconFont from '@/components/icons/IconFont.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormSimpleSwitch from '@/components/form/Switch/SimpleSwitch.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import { GuiLoader } from '@library/gigant_ui';

@Component({
  components: {
    GuiLoader,
    AppRow,
    AppCol,
    FormSelect,
    FormSimpleSwitch,
    IconFont,
    AppTable,
    TabsNav,
    AppHeaderBtnAdd,
    TableTotal,
    AddBlock,
    EditBlock,
    ArrowReturn,
    Loading,
  },
})
export default class CompetenceMain extends Vue {
  activeRow = null;

  get onlyActive() {
    return CompetenceModule.onlyActive;
  }

  get tabsNav() {
    return SettingsModule.tabsNav;
  }

  get currentTab() {
    return SettingsModule.allTabs.competence?.id;
  }

  get canIsEdit() {
    return UserModule.userHasPermission('CAN_EDIT_COMPETENCE');
  }

  get isAdd() {
    return SettingsModule.isAdd;
  }

  get isEdit() {
    return SettingsModule.isEdit;
  }

  get settings() {
    return CompetenceModule.pageSettings;
  }

  get filterSettings() {
    return CompetenceModule.filter.filterSettings.filterModel;
  }

  get groupTable() {
    const table = this.settings.table as any;
    if (!table.titles) {
      return undefined;
    }

    const nameTitle = table.titles.find((title: { id: string }) => title.id === 'name');
    if (!nameTitle.sort || !nameTitle.sort.value) {
      return undefined;
    }

    return (item: { name: string }) => {
      return item.name[0].toUpperCase();
    };
  }

  @Watch('activeRow')
  changeColorRow(el: HTMLElement, oldEl: HTMLElement) {
    if (el) {
      el.classList.add('app-table-body-row--selected');
    }

    if (oldEl) {
      oldEl.classList.remove('app-table-body-row--selected');
    }
  }

  cancelEdit() {
    this.activeRow = null;
  }

  toAdd() {
    CompetenceModule.updateIsAdd(true);
  }

  toEdit(id: string, index: number) {
    const table = this.$refs.table as any;
    if (table) {
      this.activeRow = table.$el.querySelector(`.app-table-body-row--index-${index}`);
    }
    CompetenceModule.updateIsEdit({ isEdit: true, id: id.toString() });
  }

  returnBefore() {
    this.cancelEdit();
    CompetenceModule.updateIsAdd(false);
    CompetenceModule.updateIsEdit({ isEdit: false, id: '' });
  }

  selectAmount(value: string) {
    CompetenceModule.updatePageAmountItems(value);
  }

  sort(header: { id: string; sort: { value: string } }) {
    CompetenceModule.sort({ field: header.id, sort: header.sort.value || '' });
  }

  async selectHandler(type: string, params: { key: string; value: { id: string; value: string } } | string) {
    if (type === 'update') {
      CompetenceModule.filter.updateSelect(params as { key: string; value: { id: string; value: string } });
    } else if (type === 'clear') {
      CompetenceModule.filter.resetSelect(params as string);
    }

    await CompetenceModule.SET_PAGE(1);
    await CompetenceModule.filter.updateFilter();
    await CompetenceModule.getList();
  }

  async onlyActiveUpdated(value: boolean) {
    await this.$store.dispatch(CompetenceModule.pageSettings.actionPagination as string, 1);
    CompetenceModule.updateOnlyActive(value);
    await CompetenceModule.filter.updateFilter();
    await CompetenceModule.getList();
  }

  created() {
    CompetenceModule.init();
  }

  mounted() {
    SettingsModule.initTabsItems();
  }
}
