import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_form_block = _resolveComponent("form-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(_component_form_block, {
      "type-action": "add",
      onSave: _cache[0] || (_cache[0] = ($event: any) => (_ctx.save($event))),
      onReturnBefore: _cache[1] || (_cache[1] = ($event: any) => (_ctx.returnBefore($event)))
    })
  ]))
}