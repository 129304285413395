import { resolveComponent as _resolveComponent, createVNode as _createVNode, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, Fragment as _Fragment, createElementVNode as _createElementVNode, withCtx as _withCtx, toDisplayString as _toDisplayString } from "vue"

const _hoisted_1 = { class: "cp-admin-title-tab__wrapper" }
const _hoisted_2 = { class: "cp-flex" }
const _hoisted_3 = {
  ref: "filter",
  class: "cp-flex cp-flex--justify-between align-items-end cp-margin__b--small-14"
}
const _hoisted_4 = { class: "cp-padding__r--md" }
const _hoisted_5 = {
  key: 0,
  class: "cp-flex--inline cp-vertical-align-top cp-float_right"
}
const _hoisted_6 = { class: "cp-flex cp-flex--align-center cp-flex--justify-between" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_tabs_nav = _resolveComponent("tabs-nav")!
  const _component_table_total = _resolveComponent("table-total")!
  const _component_app_header_btn_add = _resolveComponent("app-header-btn-add")!
  const _component_arrow_return = _resolveComponent("arrow-return")!
  const _component_form_select = _resolveComponent("form-select")!
  const _component_app_col = _resolveComponent("app-col")!
  const _component_gui_loader = _resolveComponent("gui-loader")!
  const _component_form_simple_switch = _resolveComponent("form-simple-switch")!
  const _component_icon_font = _resolveComponent("icon-font")!
  const _component_app_table = _resolveComponent("app-table")!
  const _component_add_block = _resolveComponent("add-block")!
  const _component_edit_block = _resolveComponent("edit-block")!
  const _component_app_row = _resolveComponent("app-row")!

  return (_openBlock(), _createBlock(_component_app_row, {
    wrap: "nowrap",
    direction: "column",
    class: "cp-main-inner__wrapper cp-page__competence"
  }, {
    default: _withCtx(() => [
      _createVNode(_component_app_col, { grow: 0 }, {
        default: _withCtx(() => [
          _createElementVNode("div", _hoisted_1, [
            _createVNode(_component_tabs_nav, {
              items: _ctx.tabsNav,
              "active-id": _ctx.currentTab
            }, null, 8, ["items", "active-id"]),
            _createElementVNode("div", _hoisted_2, [
              _createVNode(_component_table_total, {
                class: "cp-margin__r--sx",
                "total-items": _ctx.settings.table.totalItems
              }, null, 8, ["total-items"]),
              (_ctx.canIsEdit)
                ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
                    (!_ctx.isAdd && !_ctx.isEdit)
                      ? (_openBlock(), _createBlock(_component_app_header_btn_add, {
                          key: 0,
                          onClick: _ctx.toAdd
                        }, null, 8, ["onClick"]))
                      : (_openBlock(), _createElementBlock("div", {
                          key: 1,
                          class: "cp-arrow-return",
                          onClick: _cache[0] || (_cache[0] = 
//@ts-ignore
(...args) => (_ctx.returnBefore && _ctx.returnBefore(...args)))
                        }, [
                          _createVNode(_component_arrow_return, { color: "#9B9BC7" })
                        ]))
                  ], 64))
                : _createCommentVNode("", true)
            ])
          ]),
          _createElementVNode("form", _hoisted_3, [
            _createElementVNode("div", _hoisted_4, [
              _createVNode(_component_form_select, {
                modelValue: _ctx.filterSettings.client.current,
                label: _ctx.filterSettings.client.title,
                items: _ctx.filterSettings.client.list,
                "is-search-enabled": true,
                class: "cp-width--200",
                clear: "",
                filter: "",
                onUpdate: _cache[1] || (_cache[1] = ($event: any) => (_ctx.selectHandler('update', { key: _ctx.filterSettings.client.key, value: $event }))),
                onClear: _cache[2] || (_cache[2] = ($event: any) => (_ctx.selectHandler('clear', _ctx.filterSettings.client.key)))
              }, null, 8, ["modelValue", "label", "items"])
            ])
          ], 512)
        ]),
        _: 1
      }),
      (Object.keys(_ctx.settings.table).length === 0)
        ? (_openBlock(), _createBlock(_component_app_col, {
            key: 0,
            grow: 1
          }, {
            default: _withCtx(() => [
              _createVNode(_component_gui_loader, { "absolute-center": "" })
            ]),
            _: 1
          }))
        : _createCommentVNode("", true),
      _createVNode(_component_app_col, null, {
        default: _withCtx(() => [
          _createVNode(_component_app_row, { class: "block-radius" }, {
            default: _withCtx(() => [
              (Object.keys(_ctx.settings.table).length)
                ? (_openBlock(), _createBlock(_component_app_table, {
                    key: 0,
                    ref: "table",
                    class: "app-table--row-pointer grow-1",
                    style: {"height":"calc(100vh - 104px)"},
                    headers: _ctx.settings.table.titles,
                    items: _ctx.settings.table.rows,
                    "default-footer": true,
                    page: _ctx.settings.table.pagination.currentPage,
                    "total-pages": _ctx.settings.table.pagination.totalPages,
                    "total-items": _ctx.settings.table.totalItems,
                    "page-items": _ctx.settings.pageAmountItems && +_ctx.settings.pageAmountItems,
                    "is-hover": true,
                    group: _ctx.groupTable,
                    onClickRow: _cache[3] || (_cache[3] = ($event: any) => (_ctx.toEdit($event.item.id, $event.indexItem))),
                    onChangePage: _cache[4] || (_cache[4] = ($event: any) => (_ctx.$store.dispatch(_ctx.settings.actionPagination, $event))),
                    onSelectAmount: _ctx.selectAmount,
                    onSort: _ctx.sort
                  }, {
                    "header-switch": _withCtx(({ header }) => [
                      (!_ctx.isEdit && !_ctx.isAdd)
                        ? (_openBlock(), _createElementBlock("div", _hoisted_5, [
                            _createVNode(_component_form_simple_switch, {
                              "model-value": _ctx.onlyActive,
                              name: header.id,
                              horizontal: true,
                              label: "Только активные",
                              onUpdate: _ctx.onlyActiveUpdated
                            }, null, 8, ["model-value", "name", "onUpdate"])
                          ]))
                        : _createCommentVNode("", true)
                    ]),
                    "item-name": _withCtx(({ value }) => [
                      _createElementVNode("span", _hoisted_6, [
                        _createElementVNode("span", null, _toDisplayString(value), 1),
                        _createVNode(_component_icon_font, {
                          class: "app-table-icon app-table-icon--row-hover",
                          icon: "pencil"
                        })
                      ])
                    ]),
                    _: 1
                  }, 8, ["headers", "items", "page", "total-pages", "total-items", "page-items", "group", "onSelectAmount", "onSort"]))
                : _createCommentVNode("", true),
              _createVNode(_component_app_col, null, {
                default: _withCtx(() => [
                  (_ctx.isAdd)
                    ? (_openBlock(), _createBlock(_component_add_block, { key: 0 }))
                    : (_ctx.isEdit)
                      ? (_openBlock(), _createBlock(_component_edit_block, {
                          key: 1,
                          onCancel: _ctx.cancelEdit
                        }, null, 8, ["onCancel"]))
                      : _createCommentVNode("", true)
                ]),
                _: 1
              })
            ]),
            _: 1
          })
        ]),
        _: 1
      })
    ]),
    _: 1
  }))
}