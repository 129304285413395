
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './_form.vue';

import CompetenceModule from '@/store/settings/competence';

@Component({
  components: {
    FormBlock,
  },
})
export default class CompetenceEntityAdd extends Form {
  returnBefore() {
    CompetenceModule.updateIsAdd(false);
  }
}
