
import { Component } from '@/lib/decorator';
import Form from '@/layouts/Form';
import FormBlock from './_form.vue';
import Loading from '@/components/Loading.vue';

import CompetenceModule from '@/store/settings/competence';
import CompetenceEntityModule from '@/store/settings/competence/entity';

@Component({
  components: {
    FormBlock,
    Loading,
  },
})
export default class CompetenceEntityEdit extends Form {
  get isLoading() {
    return CompetenceModule.isLoading;
  }

  returnBefore() {
    this.$emit('cancel');
    CompetenceModule.updateIsEdit({ isEdit: false, id: '' });
  }

  async remove() {
    try {
      await CompetenceEntityModule.remove();
    } catch (e) {
      console.debug('error');
    }
    this.$emit('cancel');
  }
}
