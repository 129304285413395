import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "cp-layout-relative cp-layout-height-100" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_loading = _resolveComponent("loading")!
  const _component_form_block = _resolveComponent("form-block")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_ctx.isLoading === true)
      ? (_openBlock(), _createBlock(_component_loading, {
          key: 0,
          "stroke-color": "#6E258B"
        }))
      : (_openBlock(), _createBlock(_component_form_block, {
          key: 1,
          "type-action": "edit",
          onRemove: _ctx.remove,
          onReturnBefore: _cache[0] || (_cache[0] = ($event: any) => (_ctx.returnBefore($event)))
        }, null, 8, ["onRemove"]))
  ]))
}