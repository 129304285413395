/**
 * Competence entity store.
 *
 */

import { Module, Action, getModule, Mutation } from 'vuex-module-decorators';
import { CompetenceModel, CompetenceEntity, CompetenceTextFields } from '@/interfaces/models/competence.interface';
import EntityBaseModule from '@/store/entity';
import store from '@/store';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import CompetenceModule from './index';
import {
  getAllCompetenceList,
  addNewCompetence,
  saveCompetence,
  removeCompetence,
  getCompetenceById,
} from '@/api/competence';
import { strings } from '@/lib/stringConst';
import EntityModel from '@/store/settings/competence/entityModel';
import { internshipList } from '@/lib/Competences';

export const MODULE_NAME = 'competenceEntity';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CompetenceEntityModule extends EntityBaseModule {
  model: CompetenceModel;

  constructor(module: CompetenceEntityModule) {
    super(module);

    const entityModel = new EntityModel();
    this.model = entityModel.model;
  }

  @Mutation
  SET_MODEL_VALUE(params: { key: string; value: string }) {
    (this as any).model[params.key] = params.value;
  }

  @Action({ rawError: true })
  async getAll() {
    try {
      const result = await getAllCompetenceList();
      const competence = result.find((item) => parseInt(item.id) === parseInt(this.id));

      if (competence) {
        this.setInfo(competence);
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error?.response?.data?.errors?.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async getCompetenceById(competenceId: string) {
    try {
      const competence = await getCompetenceById(competenceId);
      this.setInfo(competence);
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error?.response?.data?.errors?.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  setInfo(info: CompetenceEntity) {
    CompetenceModule.updateTitleEdit(info.name);

    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: info.name });
    this.context.commit('SET_MODEL_VALUE', { key: 'trainingId', value: info.trainingId });
    this.context.commit('SET_MODEL_VALUE', { key: 'trainingTeam', value: info.trainingTeam });
    this.context.commit('SET_MODEL_VALUE', { key: 'positionName', value: info.positionName });
    this.context.commit('SET_MODEL_VALUE', { key: 'rocketWorkDescription', value: info.rocketWorkDescription });

    const clientOption = { id: info.client.id, value: info.client.name };
    this.context.commit('SET_MODEL_VALUE', { key: 'client', value: clientOption });

    const serviceTypeOption = { id: info.serviceType.id, value: info.serviceType.name };
    this.context.commit('SET_MODEL_VALUE', { key: 'serviceType', value: serviceTypeOption });

    if (info.internship.isNeeded) {
      switch (info.internship.type) {
        case 'first_shift':
          this.context.commit('SET_MODEL_VALUE', { key: 'internship', value: internshipList[1] });
          this.context.commit('SET_MODEL_VALUE', { key: 'count', value: info.internship.hours + '' });

          break;
        case 'separate_shifts':
          this.context.commit('SET_MODEL_VALUE', { key: 'internship', value: internshipList[2] });
          break;
      }
    } else {
      this.context.commit('SET_MODEL_VALUE', { key: 'internship', value: internshipList[0] });
    }

    this.context.commit('SET_MODEL_VALUE', { key: 'securityIsNeeded', value: info.security.isNeeded });
    this.context.commit('SET_MODEL_VALUE', { key: 'isRequalification', value: info.isRequalification });
    this.context.commit('SET_MODEL_VALUE', { key: 'description', value: info.description });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: info.isActive });
    this.context.commit('SET_MODEL_VALUE', { key: 'firstShiftDescription', value: info.info });
    this.context.commit('SET_MODEL_VALUE', { key: 'trainingWebLink', value: info.trainingWebLink });
    this.context.commit('SET_MODEL_VALUE', { key: 'isAcademyOceanRequired', value: info.isAcademyOceanRequired });

    this.updateIsLoading(false);
  }

  @Action({ rawError: true })
  async addNew(params: CompetenceTextFields) {
    try {
      const data = await this.prepareData(params);
      const result = await addNewCompetence(data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: 'Компетенция создана', type: 'ok' });
        CompetenceModule.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error?.response?.data?.errors?.fields, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  async saveUpdate(params: CompetenceTextFields) {
    try {
      const data = await this.prepareData(params);
      const result = await saveCompetence(this.id, data);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: strings.SUCCESS_UPDATE, type: 'ok' });
        CompetenceModule.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      if (typeof error?.response?.data?.message !== 'undefined') {
        ResponseHandlerModule.showNotify({ message: error.response.data.message, type: 'fail' });
      } else {
        ResponseHandlerModule.showNotify({
          message: error?.response?.data?.errors?.fields,
          type: 'fail',
          defaultMessage: strings.UNKNOWN_ERROR,
        });
      }
    }
  }

  @Action({ rawError: true })
  async remove() {
    try {
      const result = await removeCompetence(this.id);

      if (!result.message) {
        ResponseHandlerModule.showNotify({ message: strings.SUCCESS_DELETE, type: 'ok' });
        CompetenceModule.getList();
      } else {
        ResponseHandlerModule.showNotify({ message: result.message, type: 'fail' });
      }
    } catch (error) {
      ResponseHandlerModule.showNotify({ message: error.response.data.message ?? strings.UNKNOWN_ERROR, type: 'fail' });
    }
  }

  @Action({ rawError: true })
  resetModel() {
    CompetenceModule.updateTitleEdit('');

    this.context.commit('SET_MODEL_VALUE', { key: 'name', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'internship', value: internshipList[0] });
    this.context.commit('SET_MODEL_VALUE', { key: 'client', value: { id: '', value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'serviceType', value: { id: '', value: '' } });
    this.context.commit('SET_MODEL_VALUE', { key: 'count', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'trainingId', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'trainingTeam', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'securityIsNeeded', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'isRequalification', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'description', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isActive', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'firstShiftDescription', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'trainingWebLink', value: '' });
    this.context.commit('SET_MODEL_VALUE', { key: 'isAcademyOceanRequired', value: false });
    this.context.commit('SET_MODEL_VALUE', { key: 'citizenships', value: '' });

    this.updateIsLoading(true);
  }

  @Action({ rawError: true })
  prepareData(params: CompetenceTextFields) {
    const data: CompetenceTextFields | {} = {};

    switch (params.internship) {
      case '0':
        (data as CompetenceModel).internshipIsNeeded = 0;
        break;
      case '1':
        (data as CompetenceModel).internshipIsNeeded = 1;
        (data as CompetenceModel).internshipType = 'first_shift';
        (data as CompetenceModel).internshipHours = +params.count;
        break;
      case '2':
        (data as CompetenceModel).internshipIsNeeded = 1;
        (data as CompetenceModel).internshipType = 'separate_shifts';
        break;
    }

    return Object.assign(data, params);
  }
}

export default getModule(CompetenceEntityModule);
