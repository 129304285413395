
import { Prop, Component } from '@/lib/decorator';
import FormEditor from '@/lib/FormEditor';

import FormInput from '@/components/form/Input/index.vue';
import FormSelect from '@/components/form/Select/index.vue';
import FormButton from '@/components/form/Button/index.vue';
import FormMultiSelect from '@/components/form/MultiSelect/index.vue';

import CompetenceEntityModule from '@/store/settings/competence/entity';
import { strings } from '@/lib/stringConst';
import SettingsModule from '@/store/settings';
import { internshipList } from '@/lib/Competences';
import FormTextarea from '@/components/form/Textarea/index.vue';
import AppCol from '@/components/ui/grid/flex/Col.vue';
import AppRow from '@/components/ui/grid/flex/Row.vue';
import AppFieldset from '@/components/ui/Fieldset/Fieldset.vue';
import ResponseHandlerModule from '@/store/modules/responseHandler';
import { CompetenceModel, CompetenceTextFields } from '@/interfaces/models/competence.interface';
import { GuiTextField, GuiTextInput, GuiFormCheckbox } from '@library/gigant_ui';
import IconFont from '@/components/icons/IconFont.vue';
import { EventBus } from '@/lib/event-bus';
import CompetenceModule from '@/store/settings/competence';

@Component({
  emits: ['returnBefore'],
  components: {
    AppRow,
    AppCol,
    AppFieldset,
    FormTextarea,
    FormInput,
    FormSelect,
    FormButton,
    FormMultiSelect,
    GuiTextField,
    GuiTextInput,
    IconFont,
    GuiFormCheckbox,
  },
})
export default class CompetenceEntityForm extends FormEditor {
  @Prop() typeAction!: string;

  name = this.model.name ?? '';
  count = this.model.count ?? '';
  positionName = this.model.positionName ?? '';
  rocketWorkDescription = this.model.rocketWorkDescription ?? '';
  securityIsNeeded = this.model.securityIsNeeded ?? false;
  isRequalification = this.model.isRequalification ?? false;
  description = this.model.description ?? '';
  firstShiftDescription = this.model.firstShiftDescription ?? '';
  isActive = this.model.isActive ?? false;

  trainingWebLink = this.model.trainingWebLink ?? '';

  internshipItems = internshipList;
  internship = this.model.internship;

  client = this.model.client;
  serviceType = this.model.serviceType;

  errorsMaxLength: Record<string, boolean> = {};

  rules = {
    count: (value: string | number): boolean | string => {
      if (this.isFirstShift && !String(value).length) return 'Поле не заполнено';

      if (this.isFirstShift && (+value < 1 || +value > 48)) return 'Некорректное число';

      return true;
    },
    link: (value: string): boolean | string => {
      if (!value.length) return true;

      const pattern = /^(ftp|http|https):\/\/[^ "]+$/;

      return pattern.test(value) || 'Некорректная ссылка';
    },
  };

  get clientItems() {
    return CompetenceModule.clientItems;
  }

  get serviceTypeItems() {
    return CompetenceModule.serviceTypeItems;
  }

  get isFirstShift(): boolean {
    return this.internship.id === '1';
  }

  get text(): Record<string, string> {
    return strings;
  }

  get model(): CompetenceModel {
    return CompetenceEntityModule.model;
  }

  get isAdd(): boolean {
    return SettingsModule.isAdd;
  }

  get isEdit(): boolean {
    return SettingsModule.isEdit;
  }

  get sendData(): CompetenceTextFields {
    return {
      name: this.name,
      count: String(this.count),
      internship: this.internship.id as string,
      client: this.client.id as string,
      serviceType: this.serviceType.id as string,
      securityIsNeeded: this.securityIsNeeded ? '1' : '0',
      isRequalification: this.isRequalification ? '1' : '0',
      description: this.description.trim(),
      info: this.firstShiftDescription.trim(),
      isActive: this.isActive ? '1' : '0',
      trainingWebLink: this.trainingWebLink,
      positionName: this.positionName,
      rocketWorkDescription: this.rocketWorkDescription,
    };
  }

  returnBefore(): void {
    this.$emit('returnBefore');
  }

  remove(): void {
    this.$emit('remove');
    this.returnBefore();
  }

  async add(): Promise<void> {
    try {
      if (this.checkMaxLengthError()) {
        ResponseHandlerModule.showNotify({ message: 'Некорректное число символов', type: 'fail' });

        return;
      }

      await CompetenceEntityModule.addNew(this.sendData);
      this.returnBefore();
    } catch (e) {
      console.debug('error');
    }
  }

  async edit(): Promise<void> {
    try {
      if (this.checkMaxLengthError()) {
        ResponseHandlerModule.showNotify({ message: 'Некорректное число символов', type: 'fail' });

        return;
      }

      await CompetenceEntityModule.saveUpdate(this.sendData);
    } catch (e) {
      console.debug('error');
    }
  }

  checkMaxLengthError(): boolean {
    for (const key in this.errorsMaxLength) {
      if (this.errorsMaxLength[key]) {
        return true;
      }
    }

    return false;
  }

  validateMaxLength(value: { name: string; length: number }): void {
    this.errorsMaxLength[value.name] = !(value.length <= 1024);
  }

  created(): void {
    if (this.typeAction === 'add') {
      this.isRequalification = true;
    }
  }

  openMarkdownModal(): void {
    EventBus.$emit('showModal', { name: 'markdown', flag: true });
  }
}
