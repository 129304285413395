import FilterFactory from '@/lib/layouts/page/filterFactory';

export default class CompetenceFilter {
  filterHandlers = {
    update: 'competenceFilter/updateFilter',
    reset: 'competenceFilter/resetFilter',
    resetState: 'competenceFilter/resetFilterState',
    removeSelected: 'competenceFilter/removeSelectedFilter',
  };
  filterModel: {};

  constructor() {
    const filter = new FilterFactory();

    filter.createSelectEntity('client');
    filter.setTitle('client', 'Клиент');
    filter.setName('client', 'select_client');
    filter.setAction('client', 'competenceFilter/updateSelect');
    filter.setInitAction('client', 'competence/initClientsSelect');
    filter.setClearAction('client', 'competenceFilter/resetSelect');
    filter.setSearchEnabled('client', true);

    this.filterModel = filter.filterModel;
  }
}
