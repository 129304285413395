/**
 * Competence store.
 *
 */

import { Module, Mutation, Action, getModule } from 'vuex-module-decorators';
import PageBaseModule from '@/store/page';
import store from '@/store';

import PageEntity from '@/lib/layouts/page/pageEntity';
import { getCompetenceList } from '@/api/competence';
import SettingsModule from '@/store/settings';
import CompetenceEntityModule from './entity';
import { TableApiInterface } from '@/lib/layouts/page/table.interface';
import { UITable } from '@/lib/util/tableUtils';
import { PageSort } from '@/lib/layouts/page/page.interface';
import filterModel from '@/store/settings/competence/filter';
import CompetenceFilter from '@/store/settings/competence/filterEntity';
import { Filter } from '@/lib/layouts/page/filter.interface';
import { getClients } from '@/api/client';
import { SelectItem } from '@/lib/formFactory/select.interface';
import { getServiceTypeAll } from '@/api/templates';

export const MODULE_NAME = 'competence';

@Module({ dynamic: true, store, name: MODULE_NAME, namespaced: true })
class CompetenceModule extends PageBaseModule {
  isLoading = false;
  filter: Filter;
  onlyActive = true;

  clientItems: SelectItem[] = [];
  serviceTypeItems: SelectItem[] = [];

  constructor(module: CompetenceModule) {
    super(module);

    const page = new PageEntity();
    page.values.actionPagination = 'competence/updatePage';
    page.setTitle('Компетенции');
    page.setTitleAdd('Добавить компетенцию');
    this.pageSettings = page.values;

    const filter = new filterModel();
    this.filter = getModule(CompetenceFilter);
    this.filter.setFilterName('competenceFilter');
    this.filter.setTemplateClassName('template-lg-with-sub');
    this.filter.setFilterModel(filter.filterModel);
    this.filter.setFilterHandlers(filter.filterHandlers);
    this.filter.setBtnClassName('col-1-row-6-full');
  }

  @Mutation
  UPDATE_IS_LOADING(value: boolean) {
    this.isLoading = value;
  }

  @Mutation
  SET_SORT(sorts: PageSort) {
    this.pageSettings.sort = sorts;
    window.localStorage.competenceSort = JSON.stringify(sorts);
  }

  @Mutation
  SET_ONLY_ACTIVE(value: boolean) {
    this.onlyActive = value;
  }

  @Mutation
  SET_CLIENTS(clients: SelectItem[]) {
    this.clientItems = clients;
  }

  @Mutation
  SET_SERVICE_TYPES(serviceTypes: SelectItem[]) {
    this.serviceTypeItems = serviceTypes;
  }

  @Action({ rawError: true })
  setList(table: TableApiInterface) {
    const uiTable = new UITable(table as any);
    const sorts = Object.values(this.pageSettings.sort);
    const tableInfo = uiTable.setSortableValues(sorts).getTable() as any;

    this.context.commit('SET_TABLE', tableInfo);
  }

  @Action({ rawError: true })
  async getList() {
    try {
      const itemsQuery = await this.context.dispatch('getItemsQuery', MODULE_NAME);
      const sort = await this.getSortForRequest();
      const filter =
        this.filter.filterSettings.filter + (this.onlyActive ? '&filters[3][id]=active&filters[3][value]=1' : '');
      const result = await getCompetenceList(this.pageSettings.pageCurrent, itemsQuery, sort, filter);
      await this.context.dispatch('setList', result);
    } catch (error) {
      this.context.commit('setGlobalError', false);
    }
  }

  @Action({ rawError: true })
  updateIsAdd(isAdd: boolean) {
    CompetenceEntityModule.resetModel();
    SettingsModule.SET_IS_EDIT(false);
    CompetenceEntityModule.SET_ID('');
    SettingsModule.SET_IS_ADD(isAdd);
  }

  @Action({ rawError: true })
  async updateIsEdit(params: { isEdit: boolean; id: string }) {
    this.context.commit('UPDATE_IS_LOADING', true);
    CompetenceEntityModule.resetModel();
    CompetenceEntityModule.SET_ID(params.id);
    SettingsModule.SET_IS_ADD(false);
    SettingsModule.SET_IS_EDIT(params.isEdit);

    if (params.id && params.id.length > 0) {
      await CompetenceEntityModule.getCompetenceById(params.id);
    }

    this.context.commit('UPDATE_IS_LOADING', false);
  }

  @Action({ rawError: true })
  async init() {
    this.pageSettings.pageAmountItems = await this.context.dispatch('getPageAmountStorageValue', MODULE_NAME);
    await this.filter.init();
    await this.getList();
    await this.initServiceTypeSelect();
  }

  @Action({ rawError: true })
  async updatePageAmountItems(number: string) {
    await this.context.commit('SET_PAGE', 1);
    await this.context.dispatch('updatePaginationSettings', {
      moduleName: MODULE_NAME,
      amountItems: number,
    });
    await this.getList();
  }

  @Action({ rawError: true })
  async initClientsSelect() {
    try {
      const result = await getClients();

      const clientItems = Object.values(result.clients).map((client) => {
        return {
          id: client.id,
          value: client.name,
        };
      });

      this.SET_CLIENTS(clientItems);

      return clientItems;
    } catch (error) {
      return [];
    }
  }

  @Action({ rawError: true })
  async initServiceTypeSelect() {
    const serviceTypes = await getServiceTypeAll();

    const serviceTypeItems = serviceTypes.map((client) => {
      return {
        id: client.id.toString(),
        value: client.name,
      };
    });

    this.SET_SERVICE_TYPES(serviceTypeItems);
  }

  @Action({ rawError: true })
  async updateOnlyActive(value: boolean) {
    await this.context.commit('SET_PAGE', 1);
    this.context.commit('SET_ONLY_ACTIVE', value);
  }
}

export default getModule(CompetenceModule);
